import {
  ActionIcon,
  Button,
  Modal,
  Progress,
  Table,
  Text,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { getauditHistory } from "../apis/obligationApi";
import { formatDatetoISO } from "../pages/utilities";
import { IconFile, IconFiles } from "@tabler/icons-react";
import { getFilesForTran } from "../apis/fileApi";

const ActionHistory = ({ id, openFile, refresh }) => {
  const [configs, setConfigs] = useState({
    loading: true,
    history: [],
  });
  const [tranId, setTranId] = useState(0);
  const [files, setFiles] = useState([]);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    const getFiles = async () => {
      console.log("tran id is :", tranId);
      const response = await getFilesForTran({ id: tranId });
      if (response.success) {
        setFiles(response.files);
        console.log("response :" , response);
      }
    };
    if (tranId) {
      getFiles();
    }
  }, [tranId]);
  useEffect(() => {
    const getHistory = async () => {
      const response = await getauditHistory({ id });
      console.log("history: ", response);
      setConfigs({ loading: false, history: response.auditHistory });
    };
    getHistory();
  }, [id, refresh]);
  let sr = 1;
  return (
    <>
      <div className="w-full  p-2">
        <div className="rounded bg-gray-100 p-2 mb-1">
          <Table striped>
            <thead className="text-center">
              <tr>
                <th style={{ textAlign: "center" }}>sr. no.</th>
                <th style={{ textAlign: "center" }}>complaince</th>
                <th style={{ textAlign: "center" }}>actioned by</th>
                <th style={{ textAlign: "center" }}>actioned at</th>
                <th style={{ textAlign: "center" }}>remarks</th>
                <th style={{ textAlign: "center" }}>files</th>
              </tr>
            </thead>
            <tbody>
              {configs.history &&
                configs.history.map((tran) => (
                  <tr key={tran.id} style={{ textAlign: "center" }}>
                    <td>{sr++}</td>
                    <td>{tran.status}</td>
                    <td>{tran.doneby}</td>
                    <td>{formatDatetoISO(tran.attime)}</td>
                    <td>{tran.extraCol1}</td>
                    <td className="flex justify-center">
                      <ActionIcon
                        onClick={() => {
                          setTranId(tran.id);
                          setModal(true);
                        }}
                      >
                        <IconFile color="#46bde1" size={24} />
                      </ActionIcon>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal size="calc(80vw)" opened={modal} onClose={() => setModal(false)}>
        <div className="m-4">
          {files?.length > 0 &&
            files.map((file) => {
              return (
                <div key={file.id} className="flex block h-10 mb-4 w-fulll">
                  <div className="center flex items-center justify-center w-1/12">
                    <svg
                      width="24"
                      height="35"
                      viewBox="0 0 24 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 9.29688V0H1.5C0.66875 0 0 0.731445 0 1.64062V33.3594C0 34.2686 0.66875 35 1.5 35H22.5C23.3312 35 24 34.2686 24 33.3594V10.9375H15.5C14.675 10.9375 14 10.1992 14 9.29688ZM18.0737 24.0632H14V29.5319C14 30.1362 13.5525 30.6257 13 30.6257H11C10.4475 30.6257 10 30.1362 10 29.5319V24.0632H5.92625C5.03375 24.0632 4.58813 22.8813 5.22188 22.1929L11.2481 15.6509C11.6637 15.199 12.335 15.199 12.7506 15.6509L18.7769 22.1929C19.4112 22.8813 18.9662 24.0632 18.0737 24.0632ZM23.5625 7.17773L17.4438 0.478516C17.1625 0.170898 16.7812 0 16.3813 0H16V8.75H24V8.33301C24 7.90234 23.8438 7.48535 23.5625 7.17773Z"
                        fill="#191D23"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col pl-3 w-full">
                    <div className="flex flex-row justify-between">
                      <div
                        style={{
                          lineHeight: "1.25rem",
                          letterSpacing: "-0.0313rem",
                          color: "#191D23",
                        }}
                      >
                        <Text className="font-normal">{file.fileName}</Text>
                      </div>
                    </div>
                    <div className="mt-3">
                      <Progress
                        color={file.errored ? "red" : "teal"}
                        size="sm"
                        value={100}
                      />
                    </div>
                  </div>
                  <div className="center inline-block">
                    <Button
                      className="mx-3 w-60"
                      color="#46BDE1"
                      variant="outline"
                      style={{
                        color: "#46BDE1",
                        borderColor: "#46BDE1",
                      }}
                      onClick={() => openFile({ file: file })}
                    >
                      open
                    </Button>
                  </div>
                </div>
              );
            })}
          {files.length === 0 && <Text>no files added for this actions</Text>}
        </div>
      </Modal>
    </>
  );
};

export default ActionHistory;
