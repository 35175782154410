import {
  Button,
  Checkbox,
  Divider,
  Modal,
  Progress,
  SegmentedControl,
  Select,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { INDIA_STATES_UNIONS } from "./constants";
import { DatePicker } from "@mantine/dates";
import { IconSearch, IconTrash } from "@tabler/icons-react";
import {
  converDateToISO,
  convertFromISO,
  convertToISO,
  convertToISO8601,
  existsAndLength,
  formatDateString,
  formatDatetoISO,
  formatDatetoIST,
  getFutureDate,
} from "./utilities";
import SignedAgreementListModal from "./SignedAgreementListModal";
import {
  fetchBuOptions,
  getNamesofAgreement,
  getTitlesOfAgreement,
} from "../apis/userApis";
import { showNotification } from "@mantine/notifications";
import AWS from "aws-sdk/global";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData } from "../redux/authSlice";
import DocumentsModal from "../componenets/documentsModal";
import {
  attachFiles,
  attachFileToParent,
  getAttacchedFiles,
  getReadUrl,
} from "../apis/fileApi";
import { createLocalAgreement } from "../apis/obligationApi";
import axios from "axios";

const LegacyAgreementForm1 = ({
  uiConfigs,
  setUiConfigs,
  setFile,
  setDetails,
}) => {
  const typeOfDocuments = [
    "Parent",
    "Supplementary",
    "Supplementary to Supplementary",
  ];
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [type, setType] = useState("Entity");
  const [useExisting, setUseExisting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [buData, setBuData] = useState(null);
  useEffect(() => {
    const getBuData = async () => {
      const response = await fetchBuOptions();
      setBuData(response.buData);
    };
    getBuData();
  }, []);
  const [secondPartyDetailsEntity, setSecondPartyDetailsEntity] = useState({
    type: "Entity",
    name: "",
    entityType: "",
    regAddress: "",
  });
  const [signedFile, setSignedFile] = useState(null);
  const [secondPartyDetailsIndi, setSecondPartyDetailsIndi] = useState({
    type: "Individual",
    indi_name: "",
    fathersname: "",
    pan: "",
  });
  const [agreementData, setAgreementData] = useState({
    agreementId: 0,
    local: true,
    relationshipType: "",
    typeOfAgreement: "",
    titleOfAgreement: "",
    dateOfContract: "",
    noOfMonths: 0,
    noOfYears: 0,
    location: "",
    businessUnit: 0,
    secondParty: { type: type },
    errors: {},
    endDate: "",
    createdBy: userData.name,
    createdById: userData.id,
    createdByEmail: userData.email,
  });

  useEffect(() => {
    if (type === "Entity") {
      setAgreementData({
        ...agreementData,
        secondParty: secondPartyDetailsEntity,
      });
    } else if (type === "Individual") {
      setAgreementData({
        ...agreementData,
        secondParty: secondPartyDetailsIndi,
      });
    }
  }, [type, secondPartyDetailsEntity, secondPartyDetailsIndi]);

  const [selectedAgreementId, setSelectedAgreementId] = useState(0);
  const [selectedAgreementData, setSelectedAgreementData] = useState({});
  const [docuementModal, setDocumentModal] = useState(false);
  console.log(selectedAgreementId, selectedAgreementData);
  const [categoryOptions, setCategoryOptions] = useState([
    "NDA",
    "Service Agreement",
  ]);
  const [nameOptions, setNameOptions] = useState([
    "truecounsel NDA",
    "tc services",
  ]);

  useEffect(() => {
    const getTitles = async () => {
      const response = await getTitlesOfAgreement();
      if (response.success) {
        setNameOptions(response.titlesOfAgreement);
      }
    };
    const getNames = async () => {
      const response = await getNamesofAgreement();
      if (response.success) {
        setCategoryOptions(response.namesOfAgreement);
      }
    };
    getTitles();
    getNames();
  }, []);

  useEffect(() => {
    if (
      selectedAgreementId > 0 &&
      JSON.stringify(selectedAgreementData).length > 0
    ) {
      setAgreementData({
        ...agreementData,
        agreementId: selectedAgreementId,
        local: false,
        relationshipType: selectedAgreementData.relationshipType,
        typeOfAgreement: selectedAgreementData.typeOfAgreement,
        titleOfAgreement: selectedAgreementData.titleOfAgreement,
        dateOfContract: selectedAgreementData.dateOfContract,
        noOfMonths: selectedAgreementData.noOfMonths
          ? selectedAgreementData.noOfMonths
          : 0,
        noOfYears: selectedAgreementData.noOfYears,
        location: selectedAgreementData.location,
        businessUnit: 1,
        endDate: selectedAgreementData.dueDateOfContract,
        createdBy: userData.name,
        createdById: userData.id,
        createdByEmail: userData.email,
      });

      setDetails({
        agreementId: selectedAgreementId,
        local: false,
        secondParty: agreementData.secondParty?.name
          ? agreementData.secondParty.name
          : agreementData.secondParty.indi_name,
        parentAgreementId: 0,
        typeOfAgreement: agreementData.typeOfAgreement,
        endDate: selectedAgreementData.dueDateOfContract,
        businessUnit: agreementData.businessUnit,
      });

      if (selectedAgreementData.secondParty.type === "Entity") {
        setSecondPartyDetailsEntity({
          ...secondPartyDetailsEntity,
          name: selectedAgreementData.secondParty.name,
          regAddress: selectedAgreementData.secondParty.address,
          entityType: selectedAgreementData.secondParty.entityType,
        });
        setDetails({
          agreementId: selectedAgreementId,
          local: false,
          secondParty: agreementData.secondParty?.name
            ? agreementData.secondParty.name
            : agreementData.secondParty.indi_name,
          parentAgreementId: 0,
          typeOfAgreement: agreementData.typeOfAgreement,
          endDate: agreementData.endDate,
          businessUnit: agreementData.businessUnit,
        });
      } else if (selectedAgreementData.secondParty.type === "Individual") {
        setSecondPartyDetailsIndi({
          ...secondPartyDetailsIndi,
          indi_name: selectedAgreementData.secondParty.indi_name,
          fathersname: selectedAgreementData.secondParty.indi_fatherName,
          pan: selectedAgreementData.secondParty.indi_pan,
        });
        setDetails({
          agreementId: selectedAgreementId,
          local: false,
          secondParty: agreementData.secondParty?.name
            ? agreementData.secondParty.name
            : agreementData.secondParty.indi_name,
          parentAgreementId: 0,
          typeOfAgreement: agreementData.typeOfAgreement,
          endDate: agreementData.endDate,
          businessUnit: agreementData.businessUnit,
        });
      }
    }
  }, [selectedAgreementData, selectedAgreementId]);
  const [url, setUrl] = useState("");
  useEffect(() => {
    const attachedfiles = async () => {
      const signedagreementList = await getAttacchedFiles({
        id: selectedAgreementId,
      });
      console.log("attached Files: ", signedagreementList.files);
      const length = signedagreementList.files?.length;

      setSignedFile(signedagreementList.files[length - 1]);

      setFile({
        hostedLink: signedagreementList.files[length - 1]?.hostedLink,
        type: signedagreementList.files[length - 1]?.mimeType,
        key: signedagreementList.files[length - 1]?.hostedLink
          ?.split("/")
          .pop(),
      });
      const params = {
        Bucket: `${process.env.REACT_APP_AWS_BUCKET}`,
        Key: signedagreementList.files[length - 1]?.hostedLink
          ?.split("/")
          .pop(),
        Expires: 600 * 60, // URL will expire in 10 hour
      };
      const previewurl = s3.getSignedUrl("getObject", params);
      setUrl(previewurl);
    };
    if (selectedAgreementId > 0) {
      attachedfiles();
    }
  }, [selectedAgreementId]);

  AWS.config.update({
    accessKeyId: `${process.env.REACT_APP_AWS_ACCESS_KAY_ID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_SECRET_ACCESS_KEY}`,
    region: `${process.env.REACT_APP_REGION}`,
  });

  const s3 = new AWS.S3();

  const uploadFileToAws = async (e) => {
    console.log("yha aaya to h ");
    const file = e.target.files[0];
    console.log("file: ", file);
    const fileName = file.name;
    console.log(fileName);
    const type = file.type;
    console.log(type);

    const response = await attachFileToParent({
      extension: "pdf",
      contentType: file.type,
    });
    console.log("response for file: ", response);
    const reader = new FileReader();
    console.log("reader : ", reader);
    reader.onload = async () => {
      const fileData = reader.result;
      const awsAxios = axios.create({
        transformRequest: (data, headers) => {
          headers["Content-Type"] = file.type;
          headers["x-ms-blob-content-type"] = file.type; // Set Content-Type based on the file's MIME type
          headers["x-ms-blob-type"] = "BlockBlob"; // Required for Azure Blob Storage
          // headers["content-length"] = fileData.byteLength;
          headers["x-ms-version"] = "2021-12-02";
          return data;
        },
      });

      const resp = awsAxios({
        url: response.signedUrl,
        method: "put",
        data: fileData,

        // onUploadProgress: (pevt) => {},
      }).then(() => {
        console.log("response after upload: ", resp);
        const fileattached = {
          fileName: file.name,
          hostedLink: response.destination,
          key: response.destination,
          type: file.type,
        };
        setSignedFile(fileattached);
        setFile(fileattached);
        
      });
    };
    console.log("came here too");
    reader.readAsArrayBuffer(file);
  };

  const openFile = async(url)=>{
    const drive = "https://drive.google.com/";
     const hostedLink =  url;
     if(hostedLink.slice(0, 10) === drive.slice(0, 10)){
      window.open(`${hostedLink}`,  "_blank")
     }else{
      console.log("hosted link : ", hostedLink);
     const response = await getReadUrl({hostedLink});
     console.log("response : ", response);
     if(response.success){
      window.open(`${response.signedUrl}`,  "_blank")
     }
     }
     
  }

  const deleteFileFromAws = async ({ e, fileId, key }) => {
    e.preventDefault();
    // const params = {
    //   Bucket: `${process.env.REACT_APP_AWS_BUCKET}`,
    //   Key: key,
    // };
    try {
      // const data = await s3.deleteObject(params).promise();
      // console.log("File deleted successfully:", data);
      setSignedFile(null);
    } catch (err) {
      console.error("Error deleting the file:", err);
    }
  };
  const validate = () => {
    const keys = {};
    if (!existsAndLength(agreementData.relationshipType)) {
      keys.relationshipType = "Please check relationship type";
    }
    if (!existsAndLength(agreementData.titleOfAgreement)) {
      keys.titleOfAgreement = "Please check title of agreement";
    }
    if (!existsAndLength(agreementData.typeOfAgreement)) {
      keys.typeOfAgreement = "Please fill type of agreement";
    }
    if (agreementData.businessUnit === 0) {
      keys.businessUnit = "Please fill Business Unit";
    }
    // if (!existsAndLength(agreementData.location)) {
    //   keys.location = "please check location";
    // }
    if (agreementData.noOfMonths + agreementData.noOfYears === 0) {
      keys.duration = "please check duration";
    }
    if (!existsAndLength(agreementData.dateOfContract)) {
      keys.dateOfContract = "please check start date";
    }
    if (!signedFile) {
      keys.files = "please upload a file";
    }
    if (Object.keys(keys).length) {
      showNotification({
        color: "red",
        title: "Service Record",
        message: "Make sure all fields are filled properly.",
      });
      setAgreementData((agreementData) => ({
        ...agreementData,
        errors: keys,
      }));
      return false;
    }
    return true;
  };
  console.log("signed file: ", signedFile);
  const save = async () => {
    setAgreementData({ ...agreementData, errors: {} });

    if (validate()) {
      console.log("agreement data: ", agreementData);
      if (agreementData.agreementId === 0 && agreementData.local) {
        const time =
          365 * agreementData.noOfYears + 30 * agreementData.noOfMonths;
        const endDate = convertToISO8601(
          converDateToISO(
            await getFutureDate(agreementData.dateOfContract, time)
          )
        );
        const updatedAgreementData = { ...agreementData, endDate: endDate };
        setAgreementData({ ...agreementData, endDate: endDate });
        console.log(agreementData);
        const response = await createLocalAgreement({
          agreementData: updatedAgreementData,
        });
        if (response.success) {
          // setAgreementData({ ...response.agreement, erros: {} });
          const id = response.agreement.agreementId;
          const fileforAttach = {
            createdBy: "virendra",
            createdByUserId: 1,
            location: signedFile.hostedLink,
            name: signedFile.fileName,
            parent: "local",
            parentId: parseInt(id),
            type: signedFile.type,
            extraCol1: signedFile.hostedLink,
          };

          const fileAttached = await attachFiles({ body: fileforAttach });
          setDetails({
            agreementId: id,
            local: true,
            secondParty: agreementData.secondParty?.name
              ? agreementData.secondParty.name
              : agreementData.secondParty.indi_name,
            parentAgreementId: 0,
            typeOfAgreement: agreementData.typeOfAgreement,
            endDate: updatedAgreementData.endDate,
            businessUnit: agreementData.businessUnit,
          });

          setUiConfigs({ currentStep: 1 });
        } else {
          alert("error h bhau");
        }
      } else if (agreementData.agreementId > 0 && !agreementData.local) {
        const date = agreementData.dateOfContract;
        // setAgreementData({...agreementData, dateOfContract: convertToISO(date) });
        const response = await createLocalAgreement({ agreementData });
        if (response.success) {
          // setAgreementData({ ...response.agreement, erros: {} });
          const id = response.agreement.agreementId;
          const fileforAttach = {
            createdBy: "virendra",
            createdByUserId: 1,
            location: signedFile.hostedLink,
            name: signedFile.fileName,
            parent: "local",
            parentId: parseInt(id),
            type: signedFile.mimeType,
            extraCol1: signedFile.hostedLink?.split("/").pop(),
          };
          const fileAttached = await attachFiles({ body: fileforAttach });
          setDetails({
            agreementId: id,
            local: true,
            secondParty: agreementData.secondParty?.name
              ? agreementData.secondParty.name
              : agreementData.secondParty.indi_name,
            parentAgreementId: 0,
            typeOfAgreement: agreementData.typeOfAgreement,
            endDate: agreementData.endDate,
            businessUnit: agreementData.businessUnit,
          });

          setUiConfigs({ currentStep: 1 });
        } else {
          alert("error h bhau", response);
        }
      }
    }
  };
  const handleCreate = (newItem) => {
    setCategoryOptions((current) => [...current, newItem]);
    setAgreementData({ ...agreementData, typeOfAgreement: newItem });
  };
  const handleNameCreate = (newItem) => {
    setNameOptions((current) => [...current, newItem]);
    setAgreementData({ ...agreementData, titleOfAgreement: newItem });
  };
  const updatedNameOptions = nameOptions.some(
    (option) => option.value === agreementData.titleOfAgreement
  )
    ? nameOptions
    : [
        ...nameOptions,
        {
          value: agreementData.titleOfAgreement,
          label: agreementData.titleOfAgreement,
        },
      ];
  const updatedCategoryOptions = categoryOptions.some(
    (option) => option.value === agreementData.typeOfAgreement
  )
    ? categoryOptions
    : [
        ...categoryOptions,
        {
          value: agreementData.typeOfAgreement,
          label: agreementData.typeOfAgreement,
        },
      ];

  // console.log("agreement data: ",agreementData);
  console.log("signedFile: ", signedFile);

  return (
    <>
      <div className="grid grid-cols-4 gap-4">
        <div className="flex items-center gap-2 ">
          <input
            type="checkbox"
            onChange={(e) => {
              setUseExisting(!useExisting);
            }}
          />
          <label htmlFor="local">Use existing agreement</label>
        </div>

        {useExisting && (
          <div
            className="flex items-center "
            style={{ width: "100%", marginRight: "10%", position: "relative" }}
          >
            <TextInput
              label="Connected Agreement"
              placeholder="Enter agreement number"
              type="number"
              value={selectedAgreementId}
              style={{
                width: "58%",
                marginRight: "2%",
                // marginLeft: "25%"
              }}
            />
            <Button
              className="flex items-center justify-center"
              onClick={(e) => setShowModal(true)}
              style={{
                backgroundColor: "#46bde1",
                width: "20%",
                marginRight: "20%",
                position: "absolute",
                bottom: "0",
                right: "0",
                padding: "0",
              }}
            >
              <IconSearch size={20} strokeWidth={3} />
            </Button>
          </div>
        )}
      </div>

      <div className="w-full flex flex-col mt-2">
        <div className="grid grid-cols-4 gap-4">
          <div>
            <Select
              required
              className="max-w-lg"
              label="type of document"
              placeholder="select type of document"
              style={{ maxWidth: "82%" }}
              data={typeOfDocuments}
              value={agreementData.relationshipType}
              onChange={(val) => {
                selectedAgreementId === 0 &&
                  setAgreementData({ ...agreementData, relationshipType: val });
              }}
              error={agreementData.errors?.relationshipType}
            />
          </div>
          {/* {agreementData.relationshipType!=="Parent" &&
          <div>
            <TextInput
              required
              className="max-w-lg"
              label="select Parent agreement Id"

            />
          </div>} */}
          <Select
            searchable
            required
            className="max-w-lg"
            style={{ maxWidth: "82%" }}
            label="agreement category"
            placeholder="select agreement category"
            data={updatedCategoryOptions}
            creatable
            getCreateLabel={(query) => `+ Add "${query}"`}
            onCreate={handleCreate}
            onChange={(value) => {
              setAgreementData({ ...agreementData, typeOfAgreement: value });
            }}
            value={agreementData.typeOfAgreement}
            error={agreementData.errors?.typeOfAgreement}
          />
          <Select
            searchable
            required
            className="max-w-lg"
            label="agreement name"
            placeholder="select agreement name"
            style={{ maxWidth: "82%" }}
            data={updatedNameOptions}
            creatable
            onCreate={handleNameCreate}
            getCreateLabel={(query) => `+ Add "${query}"`}
            onChange={(Selected) => {
              {
                selectedAgreementId === 0 &&
                  setAgreementData({
                    ...agreementData,
                    titleOfAgreement: Selected,
                  });
              }
            }}
            value={agreementData.titleOfAgreement}
            error={agreementData.errors?.titleOfAgreement}
          />
        </div>
        <Divider className="mt-2" />
        <Text size="md" color="#46BDE1">
          term & location
        </Text>
        <div className="grid grid-cols-2 mt-2">
          <div className="flex flex-row ">
            <DatePicker
              required
              style={{ marginBottom: "20px", width: "41%", marginRight: "4%" }}
              label="starting date"
              placeholder="select starting date"
              value={
                agreementData.dateOfContract
                  ? new Date(agreementData.dateOfContract)
                  : ""
              }
              onChange={async (val) => {
                console.log("date: ");
                {
                  selectedAgreementId === 0 &&
                    setAgreementData({
                      ...agreementData,
                      dateOfContract: await convertToISO8601(
                        converDateToISO(val)
                      ),
                    });
                }
              }}
              error={agreementData.errors?.dateOfContract}
            />
            <div
              className="flex flex-row"
              style={{ marginBottom: "20px", width: "37%", marginRight: "4%" }}
            >
              <Select
                required
                label="duration"
                placeholder="select years"
                data={[
                  ...Array.from({ length: 11 }, (_, i) => ({
                    value: i,
                    label: `${i} year${i === 1 ? "" : "s"}`,
                  })),
                  { value: 100, label: "100 years" }, // Add the option for 100 years
                ]}
                onChange={(selectedOption) => {
                  {
                    selectedAgreementId === 0 &&
                      setAgreementData({
                        ...agreementData,
                        noOfYears: selectedOption,
                      });
                  }
                }}
                value={agreementData.noOfYears}
                style={{ width: "160px", marginRight: "20px" }}
                error={agreementData.errors?.duration}
              />
              <Select
                label="   "
                placeholder="select months"
                data={[
                  { value: 0 || null, label: "0 month" },
                  ...Array.from({ length: 12 }, (_, i) => ({
                    value: i + 1,
                    label: `${i + 1} month${i === 0 || i === 1 ? "" : "s"}`,
                  })),
                ]}
                onChange={(selectedOption) => {
                  {
                    selectedAgreementId === 0 &&
                      setAgreementData({
                        ...agreementData,
                        noOfMonths: selectedOption,
                      });
                  }
                }}
                value={agreementData.noOfMonths}
                style={{ width: "160px" }}
              />
            </div>
          </div>
          <div className="flex flex-row ">
            {/* <Select
              style={{ marginBottom: "20px", width: "37%", marginRight: "4%" }}
              required
              searchable
              placeholder="enter location"
              label="location"
              data={[...INDIA_STATES_UNIONS]}
              value={agreementData.location}
              onChange={(val) => {
                {
                  selectedAgreementId === 0 &&
                    setAgreementData({
                      ...agreementData,
                      location: val,
                    });
                }
              }}
              error={agreementData.errors?.location}
            /> */}
            {buData !== null && (
              <Select
                required
                searchable
                placeholder="enter business unit"
                label="business unit"
                data={buData}
                value={agreementData.businessUnit}
                onChange={(val) => {
                  setAgreementData({
                    ...agreementData,
                    businessUnit: val,
                  });
                }}
                // value={legacyAgreementFormValue.businessUnit}
                dropdownPosition="bottom"
                style={{ width: "41%" }}
                error={agreementData.errors?.businessUnit}
              />
            )}
          </div>
          {/* <TextInput required label="Location" placeholder='Enter location' {...legacyAgreementFormValue.getInputProps('location')}/> */}
        </div>
      </div>
      <Divider className="my-2" />
      <>
        <div className="flex flex-col">
          <div className="flex justify-between">
            <Text color="#46BDE1">second party details</Text>
            <SegmentedControl
              value={type}
              color="cyan"
              data={["Entity", "Individual"]}
              onChange={(val) => {
                setType(val);
              }}
            />
          </div>
          <div className="grid grid-cols-3 gap-4">
            {type === "Entity" && (
              <>
                <Select
                  required
                  className="inputCustom "
                  label="entity Type"
                  style={{
                    width: "100%",
                    marginLeft: "0px",
                    lineHeight: "1.55",
                  }}
                  data={[
                    { value: "Company", label: "Company" },
                    { value: "LLP", label: "LLP" },
                    { value: "Partnership Firm", label: "Partnership Firm" },
                    { value: "Sole Proprietary", label: "Sole Proprietary" },
                  ]}
                  value={secondPartyDetailsEntity.entityType}
                  onChange={(selectedOption) => {
                    selectedAgreementId === 0 &&
                      setSecondPartyDetailsEntity({
                        ...secondPartyDetailsEntity,
                        entityType: selectedOption,
                      });
                  }}
                />
                <TextInput
                  required
                  searchable
                  className="inputCustom"
                  label="registered name"
                  placeholder="enter registered name"
                  value={secondPartyDetailsEntity.name}
                  onChange={(e) => {
                    selectedAgreementId === 0 &&
                      setSecondPartyDetailsEntity({
                        ...secondPartyDetailsEntity,
                        name: e.target.value,
                      });
                  }}
                  style={{
                    width: "100%",
                    lineHeight: "1.55",
                  }}
                />

                <Textarea
                  label="registered address"
                  placeholder="enter registered address"
                  rows="1"
                  value={secondPartyDetailsEntity.regAddress}
                  onChange={(e) => {
                    selectedAgreementId === 0 &&
                      setSecondPartyDetailsEntity({
                        ...secondPartyDetailsEntity,
                        regAddress: e.target.value,
                      });
                  }}
                />
              </>
            )}
            {type === "Individual" && (
              <>
                <TextInput
                  required
                  label="individual name"
                  placeholder="enter individual name"
                  value={secondPartyDetailsIndi.indi_name}
                  onChange={(e) => {
                    selectedAgreementId === 0 &&
                      setSecondPartyDetailsIndi({
                        ...secondPartyDetailsIndi,
                        indi_name: e.target.value,
                      });
                  }}
                />
                <TextInput
                  label="father's name"
                  placeholder="enter father's name"
                  value={secondPartyDetailsIndi.fathersname}
                  onChange={(e) => {
                    selectedAgreementId === 0 &&
                      setSecondPartyDetailsIndi({
                        ...secondPartyDetailsIndi,
                        fathersname: e.target.value,
                      });
                  }}
                />
                <TextInput
                  label="PAN no."
                  placeholder="enter PAN no."
                  value={secondPartyDetailsIndi.pan}
                  onChange={(e) => {
                    selectedAgreementId === 0 &&
                      setSecondPartyDetailsIndi({
                        ...secondPartyDetailsIndi,
                        pan: e.target.value,
                      });
                  }}
                />
              </>
            )}
          </div>
        </div>
        <Divider className="mt-2" />
        <Text color="#46BDE1" className="my-2">
          upload agreement
        </Text>

        {signedFile === null && (
          <>
            {/* <div className="flex justify-center">
              <Button
                style={{
                  backgroundColor: "#46BDE1",
                }}
                onClick={() => setDocumentModal(true)}
              >
                choose from documents
              </Button>
            </div>
            <div className="flex items-center my-3">
              <hr className="flex-grow border-dotted border-t-2 gap-2 border-gray-400" />
              <span className="mx-2 text-gray-600">or</span>
              <hr className="flex-grow border-dotted border-t-2 border-gray-400" />
            </div> */}

            <div
              error={agreementData.errors?.files}
              className="flex items-center justify-center w-full"
            >
              <label
                for="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-35 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-2 pb-2">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    ariaHidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    pdf
                  </p>
                </div>
                <input
                  onChange={async (e) => {
                    uploadFileToAws(e);
                  }}
                  accept=".pdf"
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                />
              </label>
            </div>
          </>
        )}
        <div className="mt-4">
          {signedFile && (
            <div className="flex block h-10 mb-4 w-fulll">
              <div className="center w-1/12 inline-block">
                <svg
                  width="24"
                  height="35"
                  viewBox="0 0 24 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 9.29688V0H1.5C0.66875 0 0 0.731445 0 1.64062V33.3594C0 34.2686 0.66875 35 1.5 35H22.5C23.3312 35 24 34.2686 24 33.3594V10.9375H15.5C14.675 10.9375 14 10.1992 14 9.29688ZM18.0737 24.0632H14V29.5319C14 30.1362 13.5525 30.6257 13 30.6257H11C10.4475 30.6257 10 30.1362 10 29.5319V24.0632H5.92625C5.03375 24.0632 4.58813 22.8813 5.22188 22.1929L11.2481 15.6509C11.6637 15.199 12.335 15.199 12.7506 15.6509L18.7769 22.1929C19.4112 22.8813 18.9662 24.0632 18.0737 24.0632ZM23.5625 7.17773L17.4438 0.478516C17.1625 0.170898 16.7812 0 16.3813 0H16V8.75H24V8.33301C24 7.90234 23.8438 7.48535 23.5625 7.17773Z"
                    fill="#191D23"
                  />
                </svg>
              </div>
              <div className="flex flex-col pl-3 w-full">
                <div className="flex flex-row justify-between">
                  <div
                    style={{
                      lineHeight: "1.25rem",
                      letterSpacing: "-0.0313rem",
                      color: "#191D23",
                    }}
                  >
                    {signedFile.name ? signedFile.name : signedFile.fileName}
                  </div>
                </div>
                <div className="mt-3">
                  <Progress
                    color={signedFile.errored ? "red" : "teal"}
                    size="sm"
                    // animate={
                    //   file.fetchingUploadUrl ||
                    //   file.attaching === loadingStates.LOADING
                    // }
                    // value={
                    //   file.fetchingUploadUrl || file.errored
                    //     ? 100
                    //     : file.uploadPercentage
                    // }
                    value={100}
                  />
                </div>
              </div>
              <div className="center inline-block">
                {/* <a href={url} target="blank"> */}
                  <Button
                    className="mx-3 w-60"
                    color="#46BDE1"
                    variant="outline"
                    style={{
                      color: "#46BDE1",
                      borderColor: "#46BDE1",
                    }}
                    onClick={()=>openFile(signedFile.hostedLink)}
                  >
                    open
                  </Button>
                {/* </a> */}
              </div>
              <div className="center w-1/12 px-auto py-auto inline-block">
                <IconTrash
                  onClick={(e) =>
                    deleteFileFromAws({
                      e,
                      fileId: signedFile.id,
                      key: signedFile.key,
                    })
                  }
                  className="mx-auto"
                  size={30}
                  color="red"
                />
              </div>
            </div>
          )}
        </div>
      </>
      <div className="flex flex-row justify-between">
        <div></div>
        <Button
          className="max-w-xs right mt-5"
          style={{
            backgroundColor: "#46BDE1",
          }}
          onClick={(e) => save(e)}
        >
          next
        </Button>
      </div>
      <Modal
        onClose={() => setShowModal(false)}
        opened={showModal}
        title="search connected agreement"
        size="calc(80vw)"
      >
        <SignedAgreementListModal
          setSelectedAgreementData={setSelectedAgreementData}
          setSelectedAgreementId={setSelectedAgreementId}
          setShowModal={setShowModal}
        />
      </Modal>
      <Modal
        opened={docuementModal}
        onClose={() => setDocumentModal(false)}
        size="calc(80vw)"
        title="existing documents"
      >
        <DocumentsModal
          setSignedFile={setSignedFile}
          setDocumentsModal={setDocumentModal}
        />
      </Modal>
    </>
  );
};

export default LegacyAgreementForm1;
